import { useEffect } from "react"

import type { ReplicacheMutations } from "@productlane/api"

import { api } from "../api"
import { useSession } from "../session"
import {
	useChangelogsSubscribe,
	useCommentsSubscribe,
	useCompaniesSubscribe,
	useContactsSubscribe,
	useEmailsSubscribe,
	useIssuesSubscribe,
	useIssueUpvotesSubscribe,
	useNoteTemplatesSubscribe,
	useProjectsSubscribe,
	useRecordingsSubscribe,
	useSegmentsSubscribe,
	useSlackMessagesSubscribe,
	useSlackUsersSubscribe,
	useSnippetsSubscribe,
	useTagGroupsSubscribe,
	useTagsSubscribe,
	useThreadMessagesSubscribe,
	useThreadsSubscribe,
	useUpvotesSubscribe,
	useUtterancesSubscribe,
	useVideosSubscribe,
	useWidgetLinksSubscribe,
	useWidgetSettingsSubscribe,
	useWorkspaceSubscribe,
} from "./subscriptions"
import { useSidebarSectionsSubscribe } from "./subscriptions/sidebar-sections"

export function ReplicacheSubscribe({
	replicache,
	onAllInit,
}: {
	replicache: ReplicacheMutations | null
	onAllInit: () => void
}) {
	const { session } = useSession()
	const utils = api.useUtils()

	useEffect(() => {
		void utils.workspace.info.prefetch()
		void utils.workspace.members.prefetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { data: workspace, isInitialized } = useWorkspaceSubscribe({
		replicache,
	})

	useSegmentsSubscribe({ replicache, workspace })

	const { data: projects } = useProjectsSubscribe({ replicache, workspace })

	useThreadsSubscribe({ replicache, workspace })

	useTagsSubscribe({ replicache, workspace })

	useTagGroupsSubscribe({ replicache, workspace })

	useContactsSubscribe({ replicache, workspace })

	useCompaniesSubscribe({ replicache, workspace })

	useEmailsSubscribe({ replicache, workspace })

	useThreadMessagesSubscribe({ replicache, workspace })

	useIssuesSubscribe({ replicache, workspace })

	useChangelogsSubscribe({ replicache, workspace })

	useUpvotesSubscribe({ replicache, workspace })

	useIssueUpvotesSubscribe({ replicache, workspace })

	useVideosSubscribe({ replicache, workspace })

	useNoteTemplatesSubscribe({ replicache, workspace })

	useWidgetSettingsSubscribe({ replicache, workspace })

	useWidgetLinksSubscribe({ replicache, workspace })

	useUtterancesSubscribe({ replicache, workspace })

	useRecordingsSubscribe({ replicache, workspace })

	useCommentsSubscribe({ replicache, workspace })

	useSlackMessagesSubscribe({ replicache, workspace })

	useSlackUsersSubscribe({ replicache, workspace })

	useSnippetsSubscribe({ replicache, workspace })

	useSidebarSectionsSubscribe({ replicache, workspace })

	useEffect(() => {
		if (isInitialized && session && workspace && projects) {
			onAllInit()
		}
	}, [isInitialized, onAllInit, session, workspace, projects])
	return null
}
