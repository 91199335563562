import { create } from "zustand"

import type {
	ChangelogsGet,
	CommentsGet,
	CompaniesGet,
	ContactsGet,
	EmailsGet,
	IssuesGet,
	IssueUpvotesGet,
	NoteTemplatesGet,
	ProjectsGet,
	RecordingsGet,
	SegmentsGet,
	SidebarSectionGet,
	SlackMessagesGet,
	SlackUsersGet,
	SnippetsGet,
	TagGroupGet,
	TagsGet,
	ThreadMessagesGet,
	ThreadsGet,
	UpvotesGet,
	UtterancesGet,
	VideosGet,
	WidgetLinksGet,
	WidgetSettingsGet,
	WorkspacesGet,
} from "@productlane/api"

interface DataStoreState {
	changelogs: ChangelogsGet
	comments: CommentsGet
	companies: CompaniesGet
	contacts: ContactsGet
	emails: EmailsGet
	issues: IssuesGet
	issueUpvotes: IssueUpvotesGet
	noteTemplates: NoteTemplatesGet
	widgetSettings: WidgetSettingsGet
	widgetLinks: WidgetLinksGet
	tags: TagsGet
	tagGroups: TagGroupGet
	projects: ProjectsGet
	projectsIncludingArchived: ProjectsGet
	recordings: RecordingsGet
	segments: SegmentsGet
	threadMessages: ThreadMessagesGet
	slackMessages: SlackMessagesGet
	slackUsers: SlackUsersGet
	snippets: SnippetsGet
	sidebarSections: SidebarSectionGet
	threads: ThreadsGet
	upvotes: UpvotesGet
	utterances: UtterancesGet
	videos: VideosGet
	workspace: WorkspacesGet[number] | null
	workspaces: WorkspacesGet
	actions: {
		setChangelogs: (data: ChangelogsGet) => void
		setComments: (data: CommentsGet) => void
		setCompanies: (data: CompaniesGet) => void
		setContacts: (data: ContactsGet) => void
		setEmails: (data: EmailsGet) => void
		setIssues: (data: IssuesGet) => void
		setIssueUpvotes: (data: IssueUpvotesGet) => void
		setNoteTemplates: (data: NoteTemplatesGet) => void
		setWidgetSettings: (data: WidgetSettingsGet) => void
		setWidgetLinks: (data: WidgetLinksGet) => void
		setTags: (data: TagsGet) => void
		setTagGroups: (data: TagGroupGet) => void
		setProjects: (data: ProjectsGet) => void
		setRecordings: (data: RecordingsGet) => void
		setSegments: (data: SegmentsGet) => void
		setThreadMessages: (data: ThreadMessagesGet) => void
		setSlackMessages: (data: SlackMessagesGet) => void
		setSlackUsers: (data: SlackUsersGet) => void
		setSnippets: (data: SnippetsGet) => void
		setSidebarSections: (data: SidebarSectionGet) => void
		setThreads: (data: ThreadsGet) => void
		setUpvotes: (data: UpvotesGet) => void
		setUtterances: (data: UtterancesGet) => void
		setVideos: (data: VideosGet) => void
		setWorkspace: (data: WorkspacesGet[number]) => void
		setWorkspaces: (data: WorkspacesGet) => void
	}
}

const useDataStore = create<DataStoreState>()((set) => ({
	changelogs: [],
	comments: [],
	companies: [],
	contacts: [],
	emails: [],
	issues: [],
	issueUpvotes: [],
	noteTemplates: [],
	widgetSettings: [],
	widgetLinks: [],
	tags: [],
	tagGroups: [],
	projects: [],
	projectsIncludingArchived: [],
	recordings: [],
	segments: [],
	threadMessages: [],
	slackMessages: [],
	slackUsers: [],
	snippets: [],
	sidebarSections: [],
	threads: [],
	upvotes: [],
	utterances: [],
	videos: [],
	workspace: null,
	workspaces: [],
	actions: {
		setChangelogs: (data) => {
			set({ changelogs: data })
		},
		setComments: (data) => {
			set({ comments: data })
		},
		setCompanies: (data) => {
			set({ companies: data })
		},
		setContacts: (data) => {
			set({ contacts: data })
		},
		setEmails: (data) => {
			set({ emails: data })
		},
		setIssues: (data) => {
			set({ issues: data })
		},
		setIssueUpvotes: (data) => {
			set({ issueUpvotes: data })
		},
		setNoteTemplates: (data) => {
			set({ noteTemplates: data })
		},
		setWidgetSettings: (data) => {
			set({ widgetSettings: data })
		},
		setWidgetLinks: (data) => {
			set({ widgetLinks: data })
		},
		setTags: (data) => {
			set({ tags: data })
		},
		setTagGroups: (data) => {
			set({ tagGroups: data })
		},
		setProjects: (data) => {
			set({
				projects: data.filter((d) => !d.archived),
				projectsIncludingArchived: data,
			})
		},
		setRecordings: (data) => {
			set({ recordings: data })
		},
		setSegments: (data) => {
			set({ segments: data })
		},
		setThreadMessages: (data) => {
			set({ threadMessages: data })
		},
		setSlackMessages: (data) => {
			set({ slackMessages: data })
		},
		setSlackUsers: (data) => {
			set({ slackUsers: data })
		},
		setSnippets: (data) => {
			set({ snippets: data })
		},
		setSidebarSections: (data) => {
			set({ sidebarSections: data })
		},
		setThreads: (data) => {
			set({ threads: data })
		},
		setUpvotes: (data) => {
			set({ upvotes: data })
		},
		setUtterances: (data) => {
			set({ utterances: data })
		},
		setVideos: (data) => {
			set({ videos: data })
		},
		setWorkspace: (data) => {
			set({ workspace: data })
		},
		setWorkspaces: (data) => {
			set({ workspaces: data })
		},
	},
}))

export const useDataStoreActions = () => useDataStore((s) => s.actions)

export const useChangelogs = () => useDataStore((s) => s.changelogs)
export const useComments = () => useDataStore((s) => s.comments)
export const useCompanies = () => useDataStore((s) => s.companies)
export const useContacts = () => useDataStore((s) => s.contacts)
export const useEmails = () => useDataStore((s) => s.emails)
export const useIssues = () => useDataStore((s) => s.issues)
export const useIssueUpvotes = () => useDataStore((s) => s.issueUpvotes)
export const useNoteTemplates = () => useDataStore((s) => s.noteTemplates)
export const useWidgetSettings = () => useDataStore((s) => s.widgetSettings)
export const useWidgetLinks = () => useDataStore((s) => s.widgetLinks)
export const useTags = () => useDataStore((s) => s.tags)
export const useTagGroups = () => useDataStore((s) => s.tagGroups)
export const useProjects = () => useDataStore((s) => s.projects)
export const useProjectsIncludingArchived = () =>
	useDataStore((s) => s.projectsIncludingArchived)
export const useRecordings = () => useDataStore((s) => s.recordings)
export const useSegments = () => useDataStore((s) => s.segments)
export const useThreadMessages = () => useDataStore((s) => s.threadMessages)
export const useSlackMessages = () => useDataStore((s) => s.slackMessages)
export const useSlackUsers = () => useDataStore((s) => s.slackUsers)
export const useSnippets = () => useDataStore((s) => s.snippets)
export const useSidebarSections = () => useDataStore((s) => s.sidebarSections)
export const useThreads = () => useDataStore((s) => s.threads)
export const useUpvotes = () => useDataStore((s) => s.upvotes)
export const useUtterances = () => useDataStore((s) => s.utterances)
export const useVideos = () => useDataStore((s) => s.videos)
export const useWorkspace = () => useDataStore((s) => s.workspace)
export const useWorkspaces = () => useDataStore((s) => s.workspaces)
