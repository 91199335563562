import parse from "node-html-parser"
import { pipe } from "remeda"
import { z } from "zod"

import type { Email } from "@productlane/db"
import { convertHtmlToText } from "@productlane/lib/src/convert-html"

export const EmailContactSchema = z.object({
	name: z.string(),
	email: z.string(),
	mailboxHash: z.string(),
})
export const EmailAttachmentSchema = z.object({
	Content: z.string(),
	ContentLength: z.string().or(z.number()).optional().nullable(),
	Name: z.string(),
	ContentType: z.string(),
	ContentID: z.string().optional(),
})

export type EmailContact = z.infer<typeof EmailContactSchema>
export type EmailAttachment = z.infer<typeof EmailAttachmentSchema>

export const splitEmailChunks = (
	emailHtmlContent: string,
): [string, string] => {
	if (!emailHtmlContent) return ["", ""]

	let hiddenContent = ""

	const parsed = parse(emailHtmlContent).removeWhitespace()

	const chunks: [string, string] = [parsed.toString(), ""]

	const gmailQuotedMessage = parsed.querySelector("[class$='gmail_quote']")
	if (gmailQuotedMessage) {
		hiddenContent += gmailQuotedMessage.toString() ?? ""
		gmailQuotedMessage.remove()
	}

	const gmailSignaturePrefix = parsed.querySelector(
		"[class$='gmail_signature_prefix']",
	)
	if (gmailSignaturePrefix) {
		hiddenContent += gmailSignaturePrefix.toString() ?? ""
		gmailSignaturePrefix.remove()
	}

	const gmailSignature = parsed.querySelector("[class$='gmail_signature']")
	if (gmailSignature) {
		hiddenContent += gmailSignature.toString()
		gmailSignature.remove()
	}

	const signature = parsed.querySelector("#Signature")
	if (signature) {
		hiddenContent += signature.toString()
		signature.remove()
	}

	//outlook
	const forwardMessage = parsed.querySelector("#appendonsend")
	if (forwardMessage) {
		const parent = forwardMessage.parentNode
		if (parent) {
			let nextElement = forwardMessage.nextElementSibling
			while (nextElement) {
				if (nextElement.tagName === "DIV" || nextElement.tagName === "HR") {
					hiddenContent += nextElement.toString()
					const currentElement = nextElement
					nextElement = nextElement.nextElementSibling
					currentElement.remove()
				} else {
					nextElement = nextElement.nextElementSibling
				}
			}
		}
		hiddenContent += forwardMessage.toString()
		forwardMessage.remove()
	}

	const wordSection = parsed.querySelector("[class^='WordSection']")
	if (wordSection) {
		parsed
			.querySelectorAll("[class^='WordSection'] > div")
			.forEach((element) => {
				hiddenContent += element.toString()
				element.remove()
			})
	}

	const msOutlookMobileSignature = parsed.querySelector(
		".ms-outlook-mobile-signature",
	)
	if (msOutlookMobileSignature) {
		hiddenContent += msOutlookMobileSignature.toString()
		msOutlookMobileSignature.remove()
	}

	const frontSignature = parsed.querySelector(
		"[class^='main-style-'] > .front-signature",
	)
	if (frontSignature) {
		hiddenContent += frontSignature.toString()
		frontSignature.remove()
	}

	const blockquotes = parsed.querySelectorAll("blockquote")
	blockquotes.forEach((element) => {
		hiddenContent += element.toString()
		element.remove()
	})

	const messageSignatureSection = parsed.querySelector(
		"[name='messageSignatureSection']",
	)
	if (messageSignatureSection) {
		hiddenContent += messageSignatureSection.toString()
		messageSignatureSection.remove()
	}

	const messageReplySection = parsed.querySelector(
		"[name='messageReplySection']",
	)
	if (messageReplySection) {
		hiddenContent += messageReplySection.toString()
		messageReplySection.remove()
	}

	const regExp = /<div><\/div>|<p><\/p>|<div><br><\/div>/g
	chunks[0] = parsed.toString().replace(regExp, "")
	chunks[1] = hiddenContent.replace(regExp, "")
	return chunks
}

export const populateEmailAttachments =
	(attachments: EmailAttachment[]) => (htmlContent: Email["htmlContent"]) =>
		htmlContent.replace(/src="cid:([^"]+)"/g, (_, p1: string) => {
			// `_` is the whole matched string, e.g., 'src="cid:someid"'
			// `p1` is the first captured group, the 'someid' part after 'cid:'

			const attachment = attachments.find((a) => a.ContentID?.includes(p1))

			return attachment
				? `src="data:${attachment.ContentType};base64,${attachment.Content}"`
				: 'src=""'
		})

const EMAIL_CONTENT_CHARS_LIMIT = 1000

export const formatEmailHtmlContent = (emailContent: string) =>
	pipe(
		emailContent,
		(content) => content.replace(/<https?:\/\/\S+>/g, ""),
		(content) => content.replace(/https?:\/\/\S+/g, ""),
		(content) => splitEmailChunks(content)[0],
		convertHtmlToText,
		(content) =>
			content.length > EMAIL_CONTENT_CHARS_LIMIT
				? `${content.slice(0, EMAIL_CONTENT_CHARS_LIMIT)}...`
				: content,
	)
