import { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import type { ThreadContextState, ThreadTab } from "../thread-context"
import type { Virtualizer } from "@tanstack/react-virtual"
import { useInsightState } from "@/lib/global-state"
import { useThreads, useWorkspace } from "@/lib/replicache"
import { byInboxThreads } from "@/lib/threads/filter"
import { useFilteredNotes } from "../../notes/filter/use-filtered-notes"

export const useInitThread = (): ThreadContextState => {
	const navigate = useNavigate()
	const params = useParams()
	const id = params.id
	const tab = params.tab as ThreadTab
	const threads = useThreads()
	const workspace = useWorkspace()
	const thread = useMemo(() => {
		return threads.find((t) => t.id === id)
	}, [id, threads])
	const { inboxState: insightState } = useInsightState()

	const filteredThreads = useFilteredNotes(insightState ?? "all")
	const nextThread = useMemo(() => {
		const sameStatusThreads =
			thread?.state === "PROCESSED"
				? filteredThreads.filter(({ state }) => state === "PROCESSED")
				: thread?.state === "SNOOZED"
					? filteredThreads.filter(({ state }) => state === "SNOOZED")
					: filteredThreads.filter(byInboxThreads)
		const currIdx = sameStatusThreads.findIndex((t) => t.id === thread?.id)
		const next = sameStatusThreads[currIdx + 1] ?? null
		return next
	}, [thread?.state, thread?.id, filteredThreads])
	const prevThread = useMemo(() => {
		const sameStatusThreads =
			thread?.state === "PROCESSED"
				? filteredThreads.filter(({ state }) => state === "PROCESSED")
				: thread?.state === "SNOOZED"
					? filteredThreads.filter(({ state }) => state === "SNOOZED")
					: filteredThreads.filter(byInboxThreads)
		const currIdx = sameStatusThreads.findIndex((t) => t.id === thread?.id)
		const next = sameStatusThreads[currIdx - 1] ?? null
		return next
	}, [thread?.state, thread?.id, filteredThreads])
	const goNext = useCallback(() => {
		if (!nextThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, nextThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, nextThread, thread])
	const goPrev = useCallback(() => {
		if (!prevThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, prevThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, prevThread, thread])
	const [isReply, _setIsReply] = useState(false)
	const [isComment, _setIsComment] = useState(false)
	const setIsReply = useCallback(
		(isReply: boolean) => {
			if (
				!workspace?.emailSettings?.isEnabled &&
				!workspace?.slackConnectSettings?.length
			) {
				return
			}
			_setIsReply(isReply)
			if (isReply) {
				_setIsComment(false)
			}
		},
		[
			workspace?.emailSettings?.isEnabled,
			workspace?.slackConnectSettings?.length,
		],
	)
	const setIsComment = useCallback((isComment: boolean) => {
		_setIsComment(isComment)
		if (isComment) {
			_setIsReply(false)
		}
	}, [])
	const [transcriptVirtualizer, setTranscriptVirtualizer] = useState<{
		v: Virtualizer<HTMLDivElement, Element>
	} | null>(null)
	const [transcriptAccordionValue, setTranscriptAccordionValue] = useState<
		string | undefined
	>(undefined)
	return {
		// @ts-expect-error Thread can be undefined, needs to be fixed
		thread,
		nextThread,
		prevThread,
		goNext,
		goPrev,
		tab,
		isReply,
		setIsReply,
		isComment,
		setIsComment,
		transcriptVirtualizer,
		setTranscriptVirtualizer,
		transcriptAccordionValue,
		setTranscriptAccordionValue,
	}
}
