import type { DependencyList } from "react"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { create } from "zustand"

type PageType =
	| "insights"
	| "insight-detail"
	| "opportunities"
	| "opportunities-detail"
	| "issues"
	| "issues-detail"
	| "projects"
	| "projects-detail"
	| "companies"
	| "companies-detail"
	| "contacts"
	| "contacts-detail"
	| "edit-roadmap"
	| "search"

interface NavigationStateEntry {
	type: PageType
	url: string
}

interface NavigationState {
	pages: Array<NavigationStateEntry>
	setPages: (value: Array<NavigationStateEntry>) => void
}

export const useNavigationState = create<NavigationState>()((set) => ({
	pages: [],
	setPages: (value) => {
		set({ pages: value })
	},
}))

export function useBackNavigation() {
	const { pages } = useNavigationState()
	const navigate = useNavigate()
	const goBack = useCallback(
		(fallbackRoute: string, replace?: boolean) => {
			// try to take the second to last element
			// e.g. insights, go to insight detail => [insights, insight-detail]
			pages.pop() // remove 'insight-detail'
			const entry = pages.pop() // go to 'insights'
			// going to 'insights' pushes it back into the array
			if (entry) {
				if (replace) {
					navigate(entry.url, { replace: true })
				} else {
					navigate(entry.url)
				}
				return
			}
			if (replace) {
				navigate(fallbackRoute, { replace: true })
			} else {
				navigate(fallbackRoute)
			}
		},
		[navigate, pages],
	)
	return { goBack }
}

export function useSetBackNavigation(
	route: NavigationStateEntry,
	deps: DependencyList = [],
) {
	const { pages, setPages } = useNavigationState()
	useEffect(() => {
		function setLast() {
			const prev = pages[pages.length - 1]
			if (prev && prev.type === route.type) {
				// replace prev entry, e.g. when using 'next' between insight details
				const newPages = [...pages]
				newPages.pop()
				setPages([...newPages, route])
			} else if (prev) {
				setPages([...pages, route])
			} else {
				// no prev just push
				setPages([route])
			}
		}

		setLast()
		// router.events.on("routeChangeStart", setLast)

		// return () => {
		// 	router.events.off("routeChangeStart", setLast)
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps)
}
